/*  eslint-disable */
import { useState, useEffect, useCallback, useMemo, useRef } from "react"
import isEqual from 'lodash/isEqual'

function useDeepCompareMemoize(value) {
  const ref = useRef()

  if (!isEqual(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

function useDeepEffect(callback, dependencies) {
  useEffect(callback, useDeepCompareMemoize(dependencies))
}

function useDeepCallback(callback, dependencies) {
  return useCallback(callback, useDeepCompareMemoize(dependencies))
}

const useStateMachine = ({ states, initialState, ...options }) => {
  const context = options.context

  const [state, setState] = useState(() => {
    if (states[initialState] && states[initialState].onEnter) {
      const computedAction = states[initialState].onEnter(context)
      const computedState = computedAction
        ? states[initialState].on[computedAction] || initialState
        : initialState

      return states[computedState] ? computedState : undefined
    }

    return states[initialState] ? initialState : undefined
  })

  const [stateHistory, setStateHistory] = useState()
  const [fromAction, setFromAction] = useState({})

  useDeepEffect(() => {
    if (states[state] && states[state].onEnter) {
      const computedAction = states[state].onEnter(context)
      const newState = states[state].on[computedAction]
      if (newState) {
        setState(newState)
        setFromAction({ action: computedAction, replace: true })
      }
    }
  }, [state, context])

  const transition = useDeepCallback(
    async action => {
      let newState
      if (states[state] && action) {
        newState = states[state].on[action]
        setStateHistory({ action, state })
      } else if (states[state] && states[state].on.default) {
        const computedAction = states[state].on.default(context)
        newState = states[state].on[computedAction]
        setStateHistory({ action: computedAction, state })
      } else {
        newState = state
      }

      setState(newState)
      setFromAction({ action: action })
    },
    [state, context]
  )
  return { state, stateHistory, transition, setState, fromAction, context }
}

export default useStateMachine
