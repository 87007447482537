/*  eslint-disable */
import React, { useContext, useCallback, useReducer, useEffect } from "react"
import PropTypes from "prop-types"
import reducer, { actionTypes } from "./reducer"
import Immutable from "seamless-immutable"

export const FormContext = React.createContext({})
export { actionTypes } from "./reducer"

export const FormNameContext = React.createContext({})

export const FormProvider = ({ children }) => {
  const [formState, dispatch] = useReducer(reducer, undefined, reducer)

  return (
    <FormContext.Provider value={{ formState, dispatch }}>
      {children}
    </FormContext.Provider>
  )
}

FormProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export const useFormState = formName => {
  const { formState = Immutable({}) } = useContext(FormContext)
  return formState[formName]
}

const useFormHandlers = ({ formName, ...props }) => {
  const { formState = Immutable({}), dispatch } = useContext(FormContext)
  const values = formState.getIn([formName, "values"])

  const onSubmit = useCallback(
    async event => {
      event.preventDefault()

      dispatch({
        type: actionTypes.FORM_SUBMIT,
        formName,
      })

      try {
        await props.onSubmit(values, dispatch)
        dispatch({
          type: actionTypes.FORM_SUBMIT_SUCCESS,
          formName,
        })
      } catch (error) {
        dispatch({
          type: actionTypes.FORM_SUBMIT_ERROR,
          formName,
          error,
        })
        dispatch({
          type: actionTypes.FORM_FIELD_ERROR,
          formName,
          fieldName: "_",
          error: error.message,
        })
      }
    },
    [values, formName, props.onSubmit]
  )
  return { onSubmit }
}

//called on the first render
const useFormInitialValues = ({ formName, initialValues }) => {
  const { dispatch } = useContext(FormContext)

  useEffect(() => {
    dispatch({
      type: actionTypes.FORM_INIT,
      formName,
      initialValues: initialValues || {},
    })
  }, [JSON.stringify(initialValues)])
}

const FormNode = ({ children, initialValues, ...props }) => {
  const formName = useContext(FormNameContext)

  useFormInitialValues({ formName, initialValues })

  const { onSubmit } = useFormHandlers({
    formName,
    onSubmit: props.onSubmit,
  })

  return (
    <form {...props} onSubmit={onSubmit}>
      {children}
    </form>
  )
}

function Form({ children, name, initialValues, ...props }) {
  return (
    <FormNameContext.Provider value={name}>
      <FormNode {...props} initialValues={initialValues}>
        {children}
      </FormNode>
    </FormNameContext.Provider>
  )
}

Form.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
}

Form.defaultProps = {
  onSubmit: () => null,
  initialValues: {},
}

function useField({ name, validate, normalize }) {
  const formName = useContext(FormNameContext)
  const { formState = Immutable({}), dispatch } = useContext(FormContext)

  const onValidate = value => {
    const error = typeof validate === "function" && validate(value)

    dispatch({
      type: actionTypes.FORM_FIELD_ERROR,
      formName,
      fieldName: name,
      error,
    })

    dispatch({
      type: actionTypes.FORM_FIELD_ERROR,
      formName,
      fieldName: "_",
      error: null,
    })
  }

  const onChange = useCallback(e => {
    const value = typeof e === "object" ? e.target.value : e

    dispatch({
      type: actionTypes.FORM_FIELD_VALUE,
      formName,
      fieldName: name,
      value: typeof normalize === "function" ? normalize(value) : value,
    })

    validate && onValidate(value)
  })

  const error = formState.getIn([formName, "errors", name])
  const value = formState.getIn([formName, "values", name])
  const formMeta = formState.getIn([formName, "meta"]) || {}
  const dirty = (formMeta.dirtyFields || {})[name]

  return {
    onChange,
    value,
    error,
    dirty: !!dirty,
  }
}

function Field({ validate, normalize, ...props }) {
  const { name, component } = props

  validate = validate || component.validate
  normalize = normalize || component.normalize

  const { onChange, value = "", error, dirty } = useField({
    name,
    validate,
    normalize,
  })
  return React.createElement(component, {
    onChange,
    value,
    error,
    dirty,
    ...props,
  })
}

Field.propTypes = {
  name: PropTypes.string.isRequired,
  component: PropTypes.any.isRequired,
  validate: PropTypes.func,
  normalize: PropTypes.func,
}

Form.Field = Field

export default Form
