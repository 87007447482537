import React from "react"
import { Global, css } from "@emotion/core"

const FontFace: React.FunctionComponent<{}> = () => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: Calluna-Regular;
          src: url("/fonts/Calluna-Regular.otf") format("opentype");
        }
        @font-face {
          font-family: Brandon-Grotesque-Medium;
          src: url("/fonts/Brandon_medium.otf") format("opentype");
        }
        @font-face {
          font-family: OpenSans-Regular;
          src: url("/fonts/OpenSans-Regular.ttf") format("truetype");
        }
      `}
    />
  )
}

export default FontFace
