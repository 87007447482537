/*  eslint-disable */
import "./global.css"
import React from "react"
import PropTypes from "prop-types"

import Layout from "./src/components/Layout"
import { FormProvider } from "./src/components/Form"

import ApolloProvider from "./src/apolloClient/ApolloProvider"

import AirmasonNavigateMachine from "./src/components/AirmasonNavigateMachine"

export const wrapRootElement = ({ element }) => {
  return (
    <Layout>
      <ApolloProvider>
        <FormProvider>{element}</FormProvider>
      </ApolloProvider>
    </Layout>
  )
}

wrapRootElement.propTypes = {
  element: PropTypes.element.isRequired,
}

export const wrapPageElement = ({ element, props: { location } }) => {
  return (
    <AirmasonNavigateMachine location={location}>
      {element}
    </AirmasonNavigateMachine>
  )
}
