/*  eslint-disable */
import { useEffect } from "react"
import { navigate } from "gatsby"
import useStateMachine from "./useStateMachine"

const useNavigateMachine = ({ states, pathname, context }) => {
  const pathnameWithoutTrailingSlash = pathname

  const stateMachine = useStateMachine({
    states,
    initialState: pathnameWithoutTrailingSlash,
    context,
  })

  const { state, setState, fromAction } = stateMachine

  // Sync state machine with window.location updates
  useEffect(() => {
    if (state !== pathnameWithoutTrailingSlash) {
      setState(pathnameWithoutTrailingSlash)
    }
  }, [pathnameWithoutTrailingSlash])

  // // Navigate on state update
  useEffect(() => {
    if (state !== pathnameWithoutTrailingSlash) {
      navigate(state || pathnameWithoutTrailingSlash, {
        replace: fromAction.replace,
      })
    }
  }, [state])

  return stateMachine
}

export default useNavigateMachine
