/*  eslint-disable */
import React from "react"
import Helmet from "react-helmet"
import { ThemeProvider } from "styled-components"

import config from "../../config"
import EWebinarScript from "./ewebinar"
import FontFace from "./FontFace"

const defaultTheme = require("tailwindcss/defaultConfig").theme
const theme = { ...defaultTheme, ...require("../../../tailwind") }

const Head = () => (
  <Helmet>
    {/* <script type="text/javascript" async>
      {`(function(o){var b="https://turboeagle.co/anywhere/",t="8eefb0a364984bddabdd439956bccea1187eda0a1b354659b7acbdc18e297203",a=window.AutopilotAnywhere={_runQueue:[],run:function(){this._runQueue.push(arguments);}},c=encodeURIComponent,s="SCRIPT",d=document,l=d.getElementsByTagName(s)[0],p="t="+c(d.title||"")+"&u="+c(d.location.href||"")+"&r="+c(d.referrer||""),j="text/javascript",z,y;if(!window.Autopilot) window.Autopilot=a;if(o.app) p="devmode=true&"+p;z=function(src,asy){var e=d.createElement(s);e.src=src;e.type=j;e.async=asy;l.parentNode.insertBefore(e,l);};y=function(){z(b+t+'?'+p,true);};if(window.attachEvent){window.attachEvent("onload",y);}else{window.addEventListener("load",y,false);}})({});`}
    </script> */}
    <link rel="preconnect" href={`${config.HANDBOOK_HOST}`} />
    <link rel="preconnect" href="https://trycom.s3.amazonaws.com" />
    <link rel="preconnect"
          href="https://fonts.gstatic.com"
          crossorigin />
    <link rel="preload"
          as="style"
          href="https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap"
          onload="this.rel='stylesheet'" />
    <script src="https://tag.clearbitscripts.com/v1/pk_593faa992e333a02802d142dcb4e9e38/tags.js"></script>
  </Helmet>
)

const Layout = ({ children }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <FontFace />
          <Head />
          {children}
        </React.Fragment>
      </ThemeProvider>
    </>
  )
}

export default Layout
