const serverUrl =
  process.env.GATSBY_HANDBOOK_HOST || "https://editor.airmason.dev"

const newServerUrl = process.env.GATSBY_API_SERVER_URL || "https://api.airmason.dev"

const nodeEnv = process.env.GATSBY_NODE_ENV
const isProd = nodeEnv === "production"
const supportCenterUrl = "https://support.airmason.com/en"
const GatsbySlackPolicyFinderWebhookURL =
  process.env.GATSBY_SLACK_POLICY_FINDER_WEBHOOK_URL ||
  "https://hooks.slack.com/services/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"

const config = {
  API_HOST: `${serverUrl}/api`,
  NEW_API_HOST: `${newServerUrl}/api/v1`,
  HANDBOOK_HOST: serverUrl,
  GATSBY_ADMIN_DOMAIN:
    process.env.GATSBY_ADMIN_DOMAIN || "https://admin.airmason.dev",
  STRIPE_PUBLISHABLE_KEY: process.env.GATSBY_STRIPE_PUBLISHABLE_KEY || "",
  PRICING_PLANS: {
    START_UP: {
      YEARLY: isProd ? "price_0KF2hcigmTg6UEeQmQPwGWjv" : "plan_HJMDb5npiLFSp9",
      MONTHLY: isProd
        ? "price_0KF2hNigmTg6UEeQwQdfEv5Z"
        : "plan_HJMC2oYv8HkBH0",
    },
    SMALL_BUSINESS: {
      YEARLY: isProd ? "price_0KF2vMigmTg6UEeQWA5fpWrm" : "plan_FvlSHCQ4S2abzw",
      MONTHLY: isProd
        ? "price_0KF2vkigmTg6UEeQ4yHAbR12"
        : "plan_FPulljGHqdifq4",
    },
  },
  SUPPORT_CENTER_URL: supportCenterUrl,
  GA_MEASUREMENT_CONVERSION_ID: process.env.GATSBY_GA_MEASUREMENT_CONVERSION_ID,
  CALENDLY: {
    STARTUP: "https://calendly.com/airmason-sales/airmason-startup-demo",
    SMALL_BUSINESS:
      "https://calendly.com/airmason-sales/airmason-small-business-demo",
    ENTERPRISE: "https://calendly.com/airmason-sales/airmason-enterprise-demo",
    AUTOMATED_POLICY_UPDATES:
      "https://calendly.com/airmason-sales/automated-policy-updates",
  },
  GATSBY_SLACK_POLICY_FINDER_WEBHOOK_URL: GatsbySlackPolicyFinderWebhookURL,
}

export default config
