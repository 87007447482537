import { InMemoryCache } from "apollo-cache-inmemory"
import { ApolloClient } from "apollo-client"
import { RestLink } from "apollo-link-rest"
import gql from "graphql-tag"
import PropTypes from "prop-types"
import React from "react"
import { ApolloProvider as ApolloReactHooksProvider } from "react-apollo-hooks"

// The Fetch API is currently not implemented consistently across browsers.
// This module will enable you to use fetch in your Node code in a cross-browser compliant fashion.
import "isomorphic-fetch"

import config from "../config"

async function responseTransformer(res) {
  try {
    const data = await res.json()
    return data
  } catch (e) {
    return undefined
  }
}

// remove the industry from UserRegisterInput and UpdateUserInput
const typeDefs = gql`
  input UserRegisterInput {
    name: String!
    email: String!
    contact_number: String!
    password: String!
    company_size: String!
    plan: String!
  }

  input UpdateUserInput {
    first_name: String
    last_name: String
    email: String
    company_size: String
  }

  input UserLoginInput {
    email: String!
    password: String!
  }

  type User {
    email: String
    first_name: String
    last_name: String
  }

  input ChargeInput {
    token: String
    plan: String
    coupon: String
    name: String
    email: String
    phone: String
    last4: String
  }

  input ContactSalesInput {
    name: String!
    email: String!
    phone: String!
    replyBy: String!
    messsage: String
  }

  input PoliciesFinderCustomerMailInput {
    name: String!
    email: String!
  }
`

const ApolloProvider = ({ children }) => {
  const apolloClient = React.useMemo(() => {
    const restLink = new RestLink({
      uri: config.API_HOST,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      responseTransformer,
    })
    return new ApolloClient({
      link: restLink,
      cache: new InMemoryCache(),
      typeDefs,
    })
  })

  return (
    <ApolloReactHooksProvider client={apolloClient}>
      {React.cloneElement(children)}
    </ApolloReactHooksProvider>
  )
}

ApolloProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default ApolloProvider
