// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-advocacyterms-tsx": () => import("./../../../src/pages/advocacyterms.tsx" /* webpackChunkName: "component---src-pages-advocacyterms-tsx" */),
  "component---src-pages-automated-policy-updates-tsx": () => import("./../../../src/pages/automated-policy-updates.tsx" /* webpackChunkName: "component---src-pages-automated-policy-updates-tsx" */),
  "component---src-pages-book-demo-jsx": () => import("./../../../src/pages/book-demo.jsx" /* webpackChunkName: "component---src-pages-book-demo-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-data-processing-tsx": () => import("./../../../src/pages/data-processing.tsx" /* webpackChunkName: "component---src-pages-data-processing-tsx" */),
  "component---src-pages-enterprise-grade-employee-handbook-builder-jsx": () => import("./../../../src/pages/enterprise-grade-employee-handbook-builder.jsx" /* webpackChunkName: "component---src-pages-enterprise-grade-employee-handbook-builder-jsx" */),
  "component---src-pages-forms-copywriting-tsx": () => import("./../../../src/pages/forms/copywriting.tsx" /* webpackChunkName: "component---src-pages-forms-copywriting-tsx" */),
  "component---src-pages-forms-design-tsx": () => import("./../../../src/pages/forms/design.tsx" /* webpackChunkName: "component---src-pages-forms-design-tsx" */),
  "component---src-pages-forms-integrations-tsx": () => import("./../../../src/pages/forms/integrations.tsx" /* webpackChunkName: "component---src-pages-forms-integrations-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-payment-jsx": () => import("./../../../src/pages/payment.jsx" /* webpackChunkName: "component---src-pages-payment-jsx" */),
  "component---src-pages-plans-tsx": () => import("./../../../src/pages/plans.tsx" /* webpackChunkName: "component---src-pages-plans-tsx" */),
  "component---src-pages-policies-finder-index-tsx": () => import("./../../../src/pages/policies-finder/index.tsx" /* webpackChunkName: "component---src-pages-policies-finder-index-tsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-redirect-jsx": () => import("./../../../src/pages/redirect.jsx" /* webpackChunkName: "component---src-pages-redirect-jsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-sitemap-links-tsx": () => import("./../../../src/pages/sitemap/links.tsx" /* webpackChunkName: "component---src-pages-sitemap-links-tsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */)
}

