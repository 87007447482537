/*  eslint-disable */

import React from "react"

import useAirmasonNavigateMachine, {
  states,
} from "src/hooks/useAirmasonNavigateMachine"
import StateContext from "./StateContext"

function useStateContext(pathname) {
  const state = states[pathname]
  return state && state.context && state.context()
}

export const StateContextProvider = ({ pathname, children }) => {
  const context = useStateContext(pathname)

  return (
    <StateContext.Provider value={context}>
      {React.cloneElement(children, { key: pathname })}
    </StateContext.Provider>
  )
}

const NavigateMachine = ({ pathname, children }) => {
  const {
    state,
    transition,
    context,
    stateHistory,
  } = useAirmasonNavigateMachine({
    pathname,
  })

  return (
    <React.Fragment>
    
      {React.cloneElement(children, { state, transition, context })}
    </React.Fragment>
  )
}

const AirmasonNavigateMachine = ({ children, location }) => {
  const pathname = "/".concat(location.pathname.replace(/^\/|\/$/g, ""))

  return (
    <StateContextProvider pathname={pathname}>
      <NavigateMachine pathname={pathname}>{children}</NavigateMachine>
    </StateContextProvider>
  )
}

export default AirmasonNavigateMachine
